import gsap, { Power2 } from 'gsap';

import { Basics, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Metrics } from '../_app/cuchillo/core/Metrics';

export default class Drag {
    _draggable;
    _target;
    _container;
    drag = {
        initial: { x: 0, y: 0 },
        current: { x: 0, y: 0 }
    };
    pos = {
        stored: { x: 0, y: 0 },
        current: { x: 0, y: 0 }
    };
    isDragging = false;
    isWheeling = false;
    limit = { x: 0, y: 0 };
    factor = .07;
    opts = {
        selector: '.draggable-box',
        isMac: false,
        dragStart: () => { console.log('Drag start'); },
        dragEnd: () => { console.log('Drag end'); }
    }

    get isAnimating() {
        return this.isDragging || this.isWheeling;
    }

    constructor(__container, opts = {}) {
        this.opts = {
            ...this.opts,
            ...opts
        };

        this._target = GetBy.selector(opts.selector, __container)[0];
        this._draggable = [];
        this._container = __container;

        this.resize();

        if (this.opts.isMac && !isTouch) {
            this._mouseWheel = (e) => this.mouseWheel(e);
        } else {
            this._mouseDown = (e) => this.mouseDown(e);
            this._mouseUp = () => this.mouseUp();
            this._mouseMove = (e) => this.mouseMove(e);
        }
    }

    mount() {
        if (this.opts.isMac && !isTouch) {
            this._target.addEventListener('wheel', this._mouseWheel);
        } else {
            this._target.addEventListener(Basics.downEvent, this._mouseDown);
            this._target.addEventListener(Basics.moveEvent, this._mouseMove);
            this._target.addEventListener(Basics.upEvent, this._mouseUp);
        }
    }

    unmount() {
        if (this.opts.isMac && !isTouch) {
            this._target.removeEventListener(Basics.downEvent, this._mouseDown);
            this._target.removeEventListener(Basics.moveEvent, this._mouseMove);
            this._target.removeEventListener(Basics.upEvent, this._mouseUp);
        } else {
            this._target.removeEventListener('wheel', this._mouseWheel);
        }
    }

    mouseDown(e) {
        this.drag.initial = {
            x: Math.floor(isTouch ? e.touches[0].screenX : e.clientX),
            y: Math.floor(isTouch ? e.touches[0].screenY : e.clientY),
        };

        this.onDragStart();
    };

    onDragStart() {
        window.clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
            this.isDragging = true;
            this._target.classList.add('__dragging');
            this.opts.dragStart();
        }, 150);
    }

    mouseMove(e) {
        e.preventDefault();

        if (!this.isDragging || this.isWheeling) return;

        const x = Math.floor(isTouch ? e.touches[0].screenX : e.clientX);
        const y = Math.floor(isTouch ? e.touches[0].screenY : e.clientY);

        this.drag.current.y = y - this.drag.initial.y;
        this.drag.current.x = x - this.drag.initial.x;
    };

    mouseUp() {
        this.pos.stored.x = Maths.clamp(this.pos.stored.x + this.drag.current.x, -this.limit.x, this.limit.x);
        this.pos.stored.y = Maths.clamp(this.pos.stored.y + this.drag.current.y, -this.limit.y, this.limit.y);
        this.drag.current.x = 0;
        this.drag.current.y = 0;

        // window.clearTimeout(this.timeoutId);
        // this.timeoutId = setTimeout(() => {
        this.isDragging = false;
        // }, 150);

        this.onDragEnd();
    };

    onDragEnd() {
        window.clearTimeout(this.timeoutId);

        this.timeoutId = setTimeout(() => {
            this._target.classList.remove('__dragging');
            this.opts.dragEnd();
        }, 150);
    }

    mouseWheel(e) {
        e.preventDefault();

        if (this.isDragging) return;

        this.isWheeling = true;

        this.pos.stored.x = Maths.clamp(this.pos.stored.x - e.deltaX, -this.limit.x, this.limit.x);
        this.pos.stored.y = Maths.clamp(this.pos.stored.y - e.deltaY, -this.limit.y, this.limit.y);

        window.clearTimeout(this.wheelId);
        this.wheelId = setTimeout(() => {
            this.isWheeling = false;
        }, 150);
    }

    loop() {
        this.pos.current.x = Maths.clamp(Maths.lerp(this.pos.current.x, this.pos.stored.x + this.drag.current.x, this.factor), -this.limit.x, this.limit.x);
        this.pos.current.y = Maths.clamp(Maths.lerp(this.pos.current.y, this.pos.stored.y + this.drag.current.y, this.factor), -this.limit.y, this.limit.y);
        gsap.to(this._target, { x: this.pos.current.x, y: this.pos.current.y, duration: 0.2, ease: Power2.easeOut });
    }

    dispose() {
        this._draggable = null;
        this._targets = null;
        this._container = null;
    }

    resize() {
        this.limit.x = Math.floor((this._target.getBoundingClientRect().width - Metrics.WIDTH) / 2);
        this.limit.y = Math.floor((this._target.getBoundingClientRect().height - Metrics.HEIGHT) / 2);

        if (isSmartphone) this.factor = .15;
        else this.factor = .07;
    }
}
