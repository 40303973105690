import gsap, { Power1, Power3 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _text;
    _overlay;

    static init() {
        this._container = GetBy.id('Preloader');
        this._text = [...GetBy.class('__text', this._container)];
        this._text.map((t, i) => {
            gsap.to(t, {
                y: 0,
                duration: 1.5,
                delay: .4 + .1 * i,
                ease: Power3.easeInOut
            });
        })
        this._overlay = [...GetBy.class('__overlay', this._container)];
        gsap.set(this._overlay, { scaleY: 0 });
    }

    static update(__progress) { }

    static hide(__call) {
        let delay = 3;

        gsap.to(this._text, {
            y: '100%',
            duration: 1,
            delay,
            ease: Power3.easeInOut
        });

        gsap.to(this._overlay, {
            duration: .8,
            scaleY: '100%',
            delay: delay + .2,
            ease: Power3.easeInOut
        });

        this._container.style.transformOrigin = 'top center';
        gsap.to(this._container, {
            duration: .8,
            // opacity: 0,
            scaleY: 0,
            delay: delay + .6,
            ease: Power3.easeInOut,
            onComplete: () => {
                this._container.style.display = "none";
                if (__call) __call();
            }
        });
    }
}
