import gsap, { Power3 } from 'gsap';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';

class ScrollItem__ProjectList extends VScroll_Item {
  _projects;
  _images;
  _overlay;
  _overlay2;

  _current;
  _others;

  _visorShown = false;

  _projectIndex = -1;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._overlay = GetBy.class('__overlay', __link)[0];
    this._overlay2 = GetBy.selector('.__overlay span', __link)[0];
    gsap.set(this._overlay2, { scaleY: 0 });

    this._images = [...GetBy.class('__image', __link)];
    gsap.set(this._images, { opacity: 0 });

    this._projects = [...GetBy.class('__project', __link)];

    if (!isSmartphone) {
      this._hoverIn = e => {
        clearInterval(this.intervalID);
        this.hoverIn(e.target);
      }
      this._hoverOut = e => this.hoverOut(e.target);
      this._click = e => this.click(e);
      this._projects.map(p => {
        p.addEventListener(Basics.mouseOver, this._hoverIn);
        p.addEventListener(Basics.mouseOut, this._hoverOut);
      });
    }

    this.onShow = () => {
      this.showAnimation();
      this.initTicker();
    };
    this.onHide = () => { this.hideAnimation(); };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  showAnimation() {
    this._projects.map((p, i) => {
      const t = GetBy.class('__text', p)[0];
      gsap.to(t, {
        y: 0,
        duration: 1,
        delay: .05 * i,
        ease: Power3.easeInOut
      });
    });

    this._images.map((img, i) => {
      gsap.to(img, {
        opacity: 1,
        duration: .1,
        delay: .2 + .1 * i,
        ease: Power3.easeOut
      });
    });
  }

  hideAnimation() {
    this._projects.map(p => {
      const t = GetBy.class('__text', p)[0];
      gsap.to(t, {
        y: '-100%',
        duration: 1,
        ease: Power3.easeInOut
      });
    })
  }

  hoverIn(target) {
    this._projects.map(p => p.classList.remove('--active'));
    target.classList.add('--active');
    this.current = this._images.filter(img => img.dataset.image === target.dataset.image)[0];
    this.others = this._images.filter(img => img.dataset.image !== target.dataset.image);

    this.showVisor();
    this.hideVisor();
  }

  hoverOut(target) {
    target.classList.remove('--active');
    this.initTicker();
  }

  showVisor() {
    if (this._visorShown) return;

    this._visorShown = true;

    gsap.set(this._overlay, { scaleY: '100%' });
    gsap.set(this._overlay2, { scaleY: 0 });

    gsap.to(this._overlay2, {
      duration: .3,
      scaleY: '100%',
      ease: Power3.easeInOut
    });
  }

  hideVisor() {
    window.clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      gsap.set(this.others, { opacity: 0 });
      gsap.set(this.current, { opacity: 1 });
      const img = GetBy.selector('img', this.current)[0];
      gsap.set(img, { scaleX: 1.2, scaleY: 1.2 });

      gsap.to(img, {
        duration: .3,
        scaleX: 1,
        scaleY: 1,
        ease: Power3.easeInOut
      });

      gsap.to(this._overlay, {
        duration: .3,
        scaleY: 0,
        ease: Power3.easeInOut,
        onComplete: () => {
          this._visorShown = false;
        }
      });
    }, 300);
  }

  initTicker() {
    this.intervalID = setInterval(() => {
      if (this._projectIndex > -1) {
        this._projects[this._projectIndex].classList.remove('--active');
      }

      if (this._projectIndex < this._projects.length - 1) this._projectIndex++;
      else this._projectIndex = 0;

      this.hoverIn(this._projects[this._projectIndex]);
    }, 3000);
  }

  dispose() {
    if (!isSmartphone) {
      this._projects.map(p => {
        p.removeEventListener(Basics.mouseOver, this._hoverIn);
        p.removeEventListener(Basics.mouseOut, this._hoverOut);
      });
    }
    super.dispose();
  }
}

Scroll._registerClass('project-list', ScrollItem__ProjectList);