import { Videos } from '../_app/cuchillo/components/Videos';
import { isMobile, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import Header from '../layout/Header';
import { GetBy } from '../_app/cuchillo/core/Element';
import { gsap } from 'gsap';
import { Maths } from '../_app/cuchillo/utils/Maths';

export default class Home extends Page {
  indicatorIndex;
  indicatorCV;

  constructor() {
    super();

    Videos.init();

    const isMac = /Mac/.test(navigator.userAgent);
    if (isMac || isMobile) {
      // document.body.style.backgroundColor = 'red';
      GetBy.class('__windows', this.container)[0].remove();
    } else {
      // document.body.style.backgroundColor = 'blue';
      GetBy.class('__mac', this.container)[0].remove();
    }

    this.indicatorIndex = new Indicator(GetBy.id('Indicator-target-index'), GetBy.id('Indicator-index'));
    this.indicatorCV = new Indicator(GetBy.id('Indicator-target-cv'), GetBy.id('Indicator-cv'));
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Scroll.init(Scroll.AXIS_Y, { domResize: this.container, smooth: !isTouch, multiplicator: 1 });
    Keyboard.mountPage(this.id);

    if (this.isFirstTime) Header.directHide();
    else Header.hide();
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { this.afterShow() });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  afterShow() {
    this.indicatorIndex.loop();
    this.indicatorCV.loop();
    this.indicatorIndex.show();
    this.indicatorCV.show();

    super.afterShow();
    Scroll.start();
    Scroll.show();
  }

  //HIDE
  beforeHide() {
    this.indicatorIndex.hide();
    this.indicatorCV.hide();
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Scroll.hide();
    Wrap.hide(() => { this.afterHide(); });
  }

  loop() {
    super.loop();
    this.indicatorIndex.loop();
    this.indicatorCV.loop();
  }

  afterHide() {
    this.indicatorIndex.dispose();
    this.indicatorCV.dispose();
    Scroll.dispose();
    super.afterHide();
    Header.show();
  }
}

ControllerPage._addPage('home', Home);

class Indicator {
  container;
  target;
  isVisible = true;

  constructor(__c, __t) {
    this.container = __c;
    this.target = __t;

    this.directHide();
  }

  show() {
    this.container.style.display = 'block';
    gsap.to(this.container, { opacity: 1, duration: 0.3 });
  }

  hide() {
    gsap.to(this.container, {
      opacity: 0, duration: 0.3, onComplete: () => {
        this.container.style.display = 'none';
      }
    });
  }

  directHide() {
    this.container.style.opacity = 0;
    this.container.style.display = 'none';
  }

  loop() {
    const draggableRect = this.target.getBoundingClientRect();
    const indicatorRect = this.container.getBoundingClientRect();

    const width = indicatorRect.width;
    const height = indicatorRect.height;
    const newTop = draggableRect.top + draggableRect.height / 2;
    const newLeft = draggableRect.left + draggableRect.width / 2;

    const isTargetInsideScreen =
      newLeft >= 0 &&
      newLeft <= window.innerWidth &&
      newTop >= 0 &&
      newTop <= window.innerHeight;

    if (isTargetInsideScreen && this.isVisible) {
      // Target div is completely inside the screen, hide the indicator
      this.hide();
      this.isVisible = false;
    } else if (!isTargetInsideScreen && !this.isVisible) {
      // Target div is partially or completely outside the screen, show the indicator
      this.show();
      this.isVisible = true;
    }

    if (this.isVisible) {
      const leftLimit = - width / 2;
      const topLimit = - height / 2;
      const rightLimit = window.innerWidth + leftLimit;
      const bottomLimit = window.innerHeight + topLimit;

      const leftPos = Maths.clamp(newLeft + leftLimit, leftLimit, rightLimit);
      const topPos = Maths.clamp(newTop + topLimit, topLimit, bottomLimit);

      this.container.style.left = leftPos + 'px';
      this.container.style.top = topPos + 'px';
    }
  }

  dispose() { }
}
