import gsap, { Power2, Power3 } from 'gsap';

import { GetBy } from '../_app/cuchillo/core/Element';
import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import { Basics } from '../_app/cuchillo/core/Basics';
import { Maths } from '../_app/cuchillo/utils/Maths';
import { Functions } from '../_app/cuchillo/utils/Functions';

// const CLASSES = ['--type-1', '--type-2', '--type-3', '--type-4'];

class ScrollItem__Gallery extends VScroll_Item {
  _images = [];
  _current = 0;
  _wrapper;
  _counter;
  _infoBtn;
  _infoOverlay;
  _infoWindow;
  _infoOpen = false;
  animating = false;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this._info = () => this.toggleInfo();
    this._infoBtn = [...GetBy.class('__info-btn')];
    this._infoBtn.map(btn => {
      btn.addEventListener(Basics.clickEvent, this._info);
    })

    this._infoOverlay = GetBy.class('__info-overlay')[0];
    this._infoOverlay.addEventListener(Basics.clickEvent, this._info);

    this._infoWindow = GetBy.id('InfoWindow');
    GetBy.id('WindowsWrapper').appendChild(this._infoWindow);

    this._counter = GetBy.class('__counter', __link)[0];
    this._wrapper = GetBy.class('__wrapper', __link)[0];

    const images = [...GetBy.class('__image', __link)];
    images.map((img, i) => {
      img.style.zIndex = images.length - i;
      this._images.push(new GalleryImage(img, i));
    });
    this._total = this._images.length;

    this._click = () => this.next();
    if (this._images.length > 1) {
      this._wrapper.addEventListener(Basics.clickEvent, this._click);
    }

    this.onShow = () => {
      this._images[0].show();
    };
    this.onHide = () => { };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  next() {
    if (this.animating) return;

    this.animating = true;

    // if (this._current < this._total - 1) {
    //   this._images[this._current].hide(this._current, () => {
    //     this._current++;
    //     this.animating = false;
    //   });

    //   if (this._current + 1 < this._total - 1) this._images[this._current + 1].hide(this._current);
    //   if (this._current + 2 < this._total - 1) this._images[this._current + 2].hide(this._current);
    // } else {
    //   let i = 0;
    //   for (let index = this._images.length - 1; index >= 0; index--) {
    //     this._images[index].show(i, () => {
    //       this.animating = false;
    //       this._current = 0;
    //     });
    //     i++;
    //   }
    // }

    this._images[this._current].hide();

    if (this._current < this._total - 1) {
      this._current++;
    } else {
      this._current = 0;
    }

    this._images[this._current].show(() => {
      this.animating = false;
    });

    const y = Maths.normalize(this._total, 0, this._current);
    this._counter.style.setProperty("--y", -y);
  }

  toggleInfo() {
    const y = this._infoOpen ? 100 : 0;
    const opacity = this._infoOpen ? 0 : 1;

    this._infoOpen = !this._infoOpen;

    this._infoBtn.map(btn => {
      btn.classList[this._infoOpen ? 'add' : 'remove']('--active');
    })
    this._infoOverlay.classList[this._infoOpen ? 'add' : 'remove']('--active');

    gsap.killTweensOf(this._infoWindow);
    gsap.to(this._infoWindow, {
      y: `${y}%`,
      duration: .8,
      ease: Power3.easeInOut
    });
    gsap.to(this._infoOverlay, {
      opacity,
      duration: .4,
      ease: Power3.easeOut
    });
  }

  dispose() {
    if (this._images.length > 0) {
      this._wrapper.removeEventListener(Basics.clickEvent, this._click);
    }
    this._infoBtn.map(btn => {
      btn.removeEventListener(Basics.clickEvent, this._info);
    })
    this._infoOverlay.removeEventListener(Basics.clickEvent, this._info);
    this._infoWindow.remove();
    super.dispose();
  }
}

Scroll._registerClass('block-gallery', ScrollItem__Gallery);

class GalleryImage {
  dom;
  index;

  constructor(__img, __i) {
    this.dom = __img;
    this.index = __i;

    this.directHide();
  }

  directHide() {
    gsap.set(this.dom, {
      scaleX: .95,
      scaleY: .95,
      opacity: 0,
      duration: .3
    });
    this.dom.style.filter = 'blur(5px)';
  }

  show(__cb = () => { }) {
    this.directHide();

    gsap.to(this.dom, {
      scaleX: 1,
      scaleY: 1,
      opacity: 1,
      delay: .1,
      duration: .3,
      ease: Power2.easeOut,
      onComplete: () => {
        __cb();
      },
    });

    this.dom.style.filter = 'blur(0px)';
  }

  hide(__cb = () => { }) {
    gsap.to(this.dom, {
      scaleX: 1.05,
      scaleY: 1.05,
      duration: .3,
      ease: Power2.easeOut
    });
    gsap.to(this.dom, {
      opacity: 0,
      delay: .1,
      duration: .2,
      ease: Power2.easeOut, onComplete: () => {
        __cb();
      }
    });
    this.dom.style.filter = 'blur(5px)';
  }

  // show(__i, __cb = () => { }) {
  //   gsap.to(this.dom, {
  //     scaleX: 1,
  //     scaleY: 1,
  //     opacity: 1,
  //     duration: .3,
  //     delay: .03 * __i,
  //     ease: Power2.easeOut,
  //     onComplete: () => {
  //       if (this.index === 0) __cb();
  //     },
  //   });

  //   if (this.index === 0) this.dom.style.filter = 'blur(0px)';
  //   else if (this.index === 1) this.dom.style.filter = 'blur(2px)';
  //   else if (this.index === 2) this.dom.style.filter = 'blur(4px)';
  // }

  // hide(__i, __cb = () => { }) {
  //   console.log(this.index, __i);

  //   if (this.index === __i) {
  //     gsap.to(this.dom, {
  //       scaleX: 1.1,
  //       scaleY: 1.1,
  //       duration: .3,
  //       ease: Power2.easeOut
  //     });
  //     gsap.to(this.dom, {
  //       opacity: 0,
  //       delay: .1,
  //       duration: .2,
  //       ease: Power2.easeOut, onComplete: () => {
  //         __cb();
  //       }
  //     });
  //   } else {
  //     const diff = this.index - __i;

  //     if (diff === 1) this.dom.style.filter = 'blur(0px)';
  //     else if (diff === 2) this.dom.style.filter = 'blur(1px)';
  //     else if (diff === 3) this.dom.style.filter = 'blur(3px)';
  //   }
  // }
}
