import gsap, { Power3 } from 'gsap';

import { Scroll } from '../_app/cuchillo/scroll/Scroll';
import VScroll_Item from '../_app/cuchillo/scroll/VScroll_Item';
import Drag from '../components/Drag';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import { Functions } from '../_app/cuchillo/utils/Functions';
import { Basics, isSmartphone, isTouch } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';

class ScrollItem__WidgetLanding extends VScroll_Item {
  drag;
  items;
  target;

  //==================================================================================================================
  //          CONSTRUCTOR
  //==================================================================================================================
  constructor(__link, __index, __scroller) {
    super(__link, __index, __scroller);

    this.target = GetBy.class('__target', __link)[0];
    this.items = Functions.arrayRandom([...GetBy.class('__item', __link)]);
    this.items.map(item => {
      this.target.appendChild(item);
    });

    if (!isSmartphone) {
      this.drag = new Drag(__link, {
        selector: '.__target',
        dragStart: () => { this.removeClick(); },
        dragEnd: () => { this.addClick(); },
        isMac: /Mac/.test(navigator.userAgent)
      });
      this.drag.mount();
    }

    if (!isTouch) {
      this._hoverIn = e => this.hoverIn(e.target);
      this._hoverOut = e => this.hoverOut(e.target);
      this.items.map(p => {
        p.addEventListener(Basics.mouseOver, this._hoverIn);
        p.addEventListener(Basics.mouseOut, this._hoverOut);
      });
      this.items.map(p => {
        this.hoverOut(p);
      });
    }

    this._click = e => this.click(e);
    this.addClick();

    this._loop = () => this.loop();
    this.onShow = () => {
      this.showAnimation();
      gsap.ticker.add(this._loop);
    };
    this.onHide = () => {
      gsap.ticker.remove(this._loop);
      this.hideAnimation();
    };
    this.onMove = () => { };
  }

  //==================================================================================================================
  //          PUBLIC
  //==================================================================================================================
  showAnimation() { }

  hideAnimation() { }

  addClick() {
    // if (isSmartphone) return;

    this.items.map(p => {
      p.addEventListener(Basics.clickEvent, this._click);
    });
  }

  removeClick() {
    // if (isSmartphone) return;

    this.items.map(p => {
      p.removeEventListener(Basics.clickEvent, this._click);
    });
  }

  hoverIn(__target) {
    if (this.drag.isAnimating) return;

    this._overlay = GetBy.class('__overlay', __target)[0];
    this._overlay2 = GetBy.selector('.__overlay span:first-child', __target)[0];

    if (!this._overlay) return;

    gsap.killTweensOf(this._overlay);
    gsap.killTweensOf(this._overlay2);

    gsap.set(this._overlay, { scaleY: '100%' });
    gsap.set(this._overlay2, { scaleY: 0 });

    gsap.to(this._overlay2, {
      duration: .3,
      scaleY: '100%',
      ease: Power3.easeInOut
    });
  }

  hoverOut(__target) {
    this._overlay = GetBy.class('__overlay', __target)[0];
    this._overlay2 = GetBy.selector('.__overlay span:first-child', __target)[0];

    if (!this._overlay) return;

    gsap.killTweensOf(this._overlay);

    gsap.to(this._overlay, {
      duration: .3,
      scaleY: 0,
      ease: Power3.easeInOut
    });
  }

  click(e) {
    C.forEach("[aria-current='page']", (item) => { item.removeAttribute("aria-current") });
    ControllerPage.changePage(e.target.dataset.link);
  }

  hideVisor() {
    window.clearTimeout(this.timeoutId);

    this.timeoutId = setTimeout(() => {
      gsap.set(this.others, { opacity: 0 });
      gsap.set(this.current, { opacity: 1 });
      const img = GetBy.selector('img', this.current)[0];
      gsap.set(img, { scaleX: 1.2, scaleY: 1.2 });

      gsap.to(img, {
        duration: .3,
        scaleX: 1,
        scaleY: 1,
        ease: Power3.easeInOut
      });

      gsap.to(this._overlay, {
        duration: .3,
        scaleY: 0,
        ease: Power3.easeInOut,
        onComplete: () => {
          this._visorShown = false;
        }
      });
    }, 300);
  }

  loop() {
    if (isSmartphone) return;
    this.drag.loop();
  }

  dispose() {
    if (!isSmartphone) {
      this.drag.dispose();
    }

    if (!isTouch) {
      this.items.map(p => {
        p.removeEventListener(Basics.mouseOver, this._hoverIn);
        p.removeEventListener(Basics.mouseOut, this._hoverOut);
      });
    }

    this.removeClick();

    super.dispose();
  }

  resize() {
    super.resize();
  }
}

Scroll._registerClass('widget-landing', ScrollItem__WidgetLanding);
