import "core-js/stable";
import "regenerator-runtime/runtime";

import { gsap } from "gsap";

import { Scroll } from './_app/cuchillo/scroll/Scroll';
import { VSticky } from './_app/cuchillo/scroll/insiders/VSticky';
import { VScaleZero } from './_app/cuchillo/scroll/insiders/VScaleZero';
import { VScale } from './_app/cuchillo/scroll/insiders/VScale';
import { VInsider } from './_app/cuchillo/scroll/insiders/VInsider';
import { VDisplace } from './_app/cuchillo/scroll/insiders/VDisplace';
import { VInsiderMask } from './_app/cuchillo/scroll/insiders/VInsiderMask';
import { ScrollItem__ProjectList } from './scroll/ScrollItem__ProjectList';
import { ScrollItem__Gallery } from './scroll/ScrollItem__Gallery';
import { ScrollItem__WidgetLanding } from './scroll/ScrollItem__WidgetLanding';

import { Accessibility } from './_app/cuchillo/core/Accessibility';
import { Basics, isDebug, isTouch } from './_app/cuchillo/core/Basics';
import EventDispatcher from './_app/cuchillo/core/EventDispatcher';
import { Interaction, MrInteraction } from './_app/cuchillo/core/Interaction';
import { GetBy } from "./_app/cuchillo/core/Element";
import { Metrics } from './_app/cuchillo/core/Metrics';
import { Statics } from './_app/cuchillo/utils/Statics';
import Keyboard from './_app/cuchillo/core/Keyboard';

import LoaderController from './_app/cuchillo/loaders/LoaderController';
import PagesLoader from './_app/cuchillo/loaders/PagesLoader';
import MediaLoader from './_app/cuchillo/loaders/MediaLoader';

import { ControllerPage } from './_app/cuchillo/pages/ControllerPage';
import Page from './_app/cuchillo/pages/Page';
import Default from './pages/Default';
import Home from './pages/Home';
import NotFound from './pages/NotFound';

import BG from './_app/cuchillo/layout/Background';
import InterfaceCanvas from './_app/cuchillo/layout/InterfaceCanvas';
import Header from './layout/Header';
import Loading from './layout/Loading';
import Wrap from './layout/Wrap';

import Preloader from "./layout/Preloader";

export default class Main {
  static stats;

  // Funció per obtenir l'alçada de la pantalla de l'iPhone amb els menús visibles
  static getScreenHeight() {
    const screenHeight = window.innerHeight; // Alçada de la finestra del navegador

    // Es comprova si es tracta d'un iPhone amb el navegador Safari
    // if (
    //   /iPhone/.test(navigator.userAgent) &&
    //   /Safari/.test(navigator.userAgent)
    // ) {
    // Es verifica si el navegador està en mode de pantalla completa (sense els menús visibles)
    if (
      window.matchMedia &&
      !window.matchMedia("(display-mode: standalone)").matches
    ) {
      // Si està en mode de pantalla completa, s'utilitza l'alçada de la finestra del navegador com a valor d'alçada de la pantalla de l'iPhone
      //   return screenHeight;
      // } else {
      // Si no està en mode de pantalla completa, es resta l'alçada de la barra de navegació al valor de l'alçada de la finestra del navegador
      return screenHeight - (Math.abs(window.orientation) === 90 ? 32 : 44); // 32 és l'alçada de la barra de navegació a l'orientació paisatge i 44 a l'orientació vertical
    }
    // }

    return screenHeight;
  }

  static init() {
    Basics.id = "Aj_Cidoncha"; // ID para cookies   

    Header.init();
    Header.directHide();

    Metrics.init(() => Main.resize()); // Tamaños y resize
    Keyboard.enable(); // ESC para cerrar ventana
    Accessibility.init(); // Utils accesibilidad
    Statics.init(); // Si estamos en debug pinta un FPS counter
    Interaction.init({ ajax: true }) // Posiciones del cursor (Movimiento, click...), Acciones links, drag...

    BG.init(CMS_COLORS); // Control de paletas y color de fondo de pantallas. Automatico si añadimos un data-palette='loquesea' en el div con data-page
    InterfaceCanvas.init(); // Canvas de interface, se usa con Cursor

    LoaderController.add(new PagesLoader()); // Carga/Precarga de paginas HTML
    LoaderController.add(new MediaLoader()); // Carga/Precarga de imgs
    LoaderController.update = progress => Preloader.update(progress);
    LoaderController.onComplete = () => Main.setup();

    // this.doCuchilloInfo();
    this.setWorker();

    // Exemple d'ús
    const iphoneScreenHeight = this.getScreenHeight();
    // console.log("Alçada de la pantalla de l'iPhone: " + iphoneScreenHeight);

    GetBy.id('Main').style.height = `${iphoneScreenHeight}px`;

    // LOOP
    if (isDebug) {
      gsap.ticker.add(() => { Main.loopDebug(); });
    } else {
      gsap.ticker.add(() => { Main.loop(); });
    }

    Preloader.init();
    LoaderController.init();

  }

  static setup() {
    this.setupEvents();
    // INIT PAGE
    ControllerPage.init(Wrap.mainholder);
  }

  static setupEvents() {
    EventDispatcher.addEventListener(Page.ON_SHOW, () => {
      //Cursor.start();
      Loading.stop();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE, () => {
      //Cursor.hide();
      // Sidemenu.hide();
    });
    EventDispatcher.addEventListener(Page.ON_HIDE_END, () => {
      Loading.start();
    });

    // EventDispatcher.addEventListener(Win.ON_HIDE, () => { Scroll.setEnabled(true); });
    // EventDispatcher.addEventListener(Win.ON_SHOW, () => { Scroll.setEnabled(false); });
  }

  static resize() {
    Header.resize();
    // Sidemenu.resize();
    // ScrollTop.resize();
    InterfaceCanvas.resize();
    BG.resize();
    ControllerPage.resize();

    // GetBy.id('Main').style.height = `${Metrics.HEIGHT}px`;
  }

  static loop() {
    ControllerPage.loop();
    BG.loop();
    // Header.loop();
    // Sidemenu.loop();
    // ScrollTop.loop();
    InterfaceCanvas.loop();

    if (Scroll.isScrolling) Scroll.loop();
  }

  static loopDebug() {
    Statics.begin();
    this.loop();
    Statics.end();
  }

  static setWorker() {
    if ('serviceWorker' in navigator && !isDebug) {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then(function () { });
    }
  }
}

if (document.attachEvent ? document.readyState === "complete" : document.readyState !== "loading") {
  Main.init();
} else {
  document.addEventListener('DOMContentLoaded', Main.init);
}